<template>
  <div class="page-conference-detail">
    <el-card>
      <div class="inner-box">
        <div class="list-item">
          不同经营类目、店铺类型、进口类型会对应不同的资费及资质标准，请根据您的情况逐一匹配查询。您在此查询的资费资质信息为商家入驻所需资费资质，若您希望入驻的店铺为其他类型，请联系客服
          <span class="phone">400-890-0567</span>咨询
        </div>
        <div class="detail-box">
          <div class="detail-select">
            <div class="detail-label">
              <div>请选择或搜索您要经营的类目</div>
              <div class="underline"></div>
            </div>
            <div class="detail-select">
              <el-cascader
                placeholder="请选择"
                :options="options"
                :props="{ value: 'code', label: 'name', children: 'childCat' }"
                filterable
                @change="serachInfo"
              ></el-cascader>
            </div>
          </div>
          <div class="detail-type">
            <div class="detail-label">
              <div>入驻类型</div>
              <div class="underline"></div>
            </div>
            <div class="detail-list-box">
              <el-radio-group
                v-model="radio"
                class="detail-list"
                @change="typeInfo"
              >
                <div
                  v-for="item in typeList"
                  :key="`type${item.id}`"
                  class="detail-radio"
                >
                  <el-image
                    style="width: 170px; height: 130px; margin-bottom: 23px"
                    :src="require(`@/assets/img/${item.img}.png`)"
                    fit="fill"
                  ></el-image>
                  <el-radio :label="item.label">{{ item.title }}</el-radio>
                </div>
              </el-radio-group>
            </div>
          </div>
          <!-- 费用预估 -->
          <div class="detail-cost">
            <div class="detail-label">
              <div>费用预估</div>
              <div class="underline"></div>
            </div>
            <div class="detail-cost-content">
              <div
                v-for="(item, index) in costList"
                :key="`cost${index}`"
                class="cost-content"
              >
                <div class="cost-title">{{ item.title }}</div>
                <div class="cost-info">
                  {{ item.info }}
                </div>
                <div v-show="index < 2" class="cost-money">
                  {{ `¥${item.money === '' ? '?' : item.money}` }}
                </div>
                <div v-show="index === 2" class="cost-money">
                  {{ `${item.money === '' ? '?' : item.money}%` }}
                </div>
              </div>
            </div>
          </div>
          <!-- 资质要求 -->
          <div class="detail-certification">
            <div class="detail-label">
              <div>资质要求</div>
              <div class="underline"></div>
            </div>
            <div class="detail-certification-content">
              <el-table
                :data="tableData1"
                border
                :header-cell-style="{
                  background: '#f2f2f2',
                  color: '#333333',
                  fontSize: '12px',
                  
                  lineHeight: '16px',
                  textAlign: 'center',
                }"
              >
                <el-table-column prop="enterprise" label="企业资质" width="239">
                </el-table-column>
                <el-table-column prop="info" label="详细资料"></el-table-column>
              </el-table>
              <el-table
                :data="tableData2"
                style="margin-top: 20px"
                border
                :header-cell-style="{
                  background: '#f2f2f2',
                  color: '#333333',
                  fontSize: '12px',
                  
                  lineHeight: '16px',
                  textAlign: 'center',
                }"
              >
                <el-table-column prop="enterprise" label="品牌资质" width="239">
                </el-table-column>
                <el-table-column prop="info" label="详细资料">
                  <template slot-scope="{ row }">
                    <div v-html="row.info"></div>
                  </template>
                </el-table-column>
              </el-table>
              <el-table
                :data="tableData3"
                style="margin-top: 20px"
                border
                :header-cell-style="{
                  background: '#f2f2f2',
                  color: '#333333',
                  fontSize: '12px',
                  
                  lineHeight: '16px',
                  textAlign: 'center',
                }"
              >
                <el-table-column prop="enterprise" label="行业资质" width="239">
                </el-table-column>
                <el-table-column prop="info" label="详细资料">
                  <template slot-scope="{ row }">
                    <div v-html="row.info"></div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="in-btn" @click="joinInfo">
            <span>立即入驻</span>
            <i></i>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
// estimateApi
import { caAllApi, estimateApi } from "@/api/api-join-mall";
export default {
  data () {
    return {
      select: [],
      radio: 1,//类型选择
      typeList: [//入驻类型
        { id: 1, img: 'type1', title: '乐活秀线上店铺', label: 1 },
        { id: 2, img: 'type2', title: '乐活秀实体门店', label: 2 },
        { id: 3, img: 'type3', title: '乐活通雇主版', label: 3 },
        { id: 4, img: 'type4', title: '乐活通服务商版', label: 4 },
      ],
      costList: [
        { id: 1, info: '经营多个类目时按保证金最高的类目缴纳', title: '保证金（元）', money: '' },
        { id: 2, info: '', title: '平台使用费用（元/月）', money: '' },
        { id: 3, info: '具体以详情表格内容为准', title: '扣点', money: '' },
      ],
      tableData1: [
        { enterprise: '三证合一营业执照', info: '营业执照扫描原件或复印件加盖入驻公司红章，需确保未在企业经营异常名录中且所售商品在营业执照经营范围内。' },
        { enterprise: '法人身份证正反面', info: '公司法人身份证正反面，扫描原件或复印件加盖红章及法人签字。' },
        { enterprise: '一般纳税人资格证', info: '具备一般纳税人资格的企业提供（手机、数码、电脑办公、图书、家电类目商家必须提供），扫描原件或复印件加盖入驻公司红章。' },
        { enterprise: '银行开户行许可证', info: '基本存款账户信息或存款单或申请表可代替开户许可证，扫描原件或复印件加盖入驻公司红章。' },
      ],
      tableData2: [
        { enterprise: '旗舰店授权书', info: '由品牌注册人出具的在京东开放平台开设品牌旗舰店的独占性授权文件，扫描原件或复印件加盖入驻公司红章(经营品牌为自有，无需提供)。' },
        { enterprise: '商标注册证/商标注册申请受理通知书', info: '1、由国家知识产权局商标局颁发的商标注册证或商标注册申请受理通知书</br> 2、若办理过变更、转让、续展，请一并提供变更、转让、续展证明或受理通知书</br> 3、复印件加盖开店公司红章' },
        { enterprise: '销售授权书', info: '1、以商标持有人为源头出发的，授权至开店公司的完整授权链条</br> 2、商标持有人是开店公司/法人，无需提交授权书 </br>3、所有授权都要加盖授权方及开店公司红章' },
        { enterprise: '商标持有人身份证', info: '1、若商标持有人为个人，需提供持有人身份证正反面复印件及签字 </br>2、需加盖开店公司红章' },
      ],
      tableData3: [
        { enterprise: '食品经营许可证', info: '由品牌注册人出具的在京东开放平台开设品牌旗舰店的独占性授权文件，扫描原件或复印件加盖入驻公司红章(经营品牌为自有，无需提供)。' },
        { enterprise: '生产企业营业执照', info: '1、由国家知识产权局商标局颁发的商标注册证或商标注册申请受理通知书</br> 2、若办理过变更、转让、续展，请一并提供变更、转让、续展证明或受理通知书</br> 3、复印件加盖开店公司红章' },
        { enterprise: '食品生产许可证', info: '1、以商标持有人为源头出发的，授权至开店公司的完整授权链条</br> 2、商标持有人是开店公司/法人，无需提交授权书 </br>3、所有授权都要加盖授权方及开店公司红章' },
        { enterprise: '委托加工协议', info: '1、若商标持有人为个人，需提供持有人身份证正反面复印件及签字</br> 2、需加盖开店公司红章' },
        { enterprise: '委托生产企业食品生产许可证', info: '1、复印件加盖开店公司红章' },
      ],
      options: [],

    };
  },
  created () {
    this.bizformDictionary()
  },
  methods: {
    joinInfo () {
      if (sessionStorage.getItem('user_token')) {
        this.$router.push("/mall");
      } else {
        this.$message.warning('请先登录');
      }
    },
    typeInfo () {
      if (this.select.length > 0) {
        this.serachInfo(this.select)
      }
    },
    async serachInfo (val) {
      this.select = val
      let data = {
        bizFormCodeLevel1: val[0],
        bizFormCodeLevel2: val[1],
        bizFormCodeLevel3: val[2],
        //radio: this.radio
      }

      const res = await estimateApi(data)
      console.log(res)
      if (res.data.code === 0) {
        this.costList[0].money = res.data.data.bond
        this.costList[1].money = res.data.data.fee
        this.costList[2].money = res.data.data.points
        // this.options = this.deleteEmptyGroup(data.data)
      } else {
        this.$message.error(res.data.message)
      }
    },
    async bizformDictionary () {
      const { data, message } = await caAllApi()
      if (data.code === 0) {
        this.options = this.deleteEmptyGroup(data.data)
      } else {
        this.$message.error(message)
      }
    },
    // 处理没有children的分组
    deleteEmptyGroup (treeData) {
      return this.traversal(treeData)
    },
    //遍历数组删除空数组
    traversal (data) {
      data.map(item => {
        for (let info in item) {
          if (info === 'childCat') {
            if (item['childCat'].length > 0) {
              this.traversal(item['childCat'])
            }
            if (item['childCat'].length == 0) {
              delete item['childCat']
            }
          }
        }
      })
      return data
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../mall/assets/styles/base.scss';

::v-deep .el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  // box-shadow: 0 10px 30px 0 rgba(201, 201, 201, 0.2);
  box-shadow: none;
}
.page-conference-detail {
  .inner-box {
    .list-item {
      padding: 30px 38px 28px 40px;
      font-size: 14px;
      color: #ee9118;
      margin-bottom: 28px;
      border-radius: 10px;
      background-color: #fcfcfc;
      height: 48px;
      font-size: 14px;
      // font-family: MicrosoftYaHei;
      color: #ee9118;
      line-height: 24px;
      .phone {
        color: #4949f2;
        text-decoration: underline;
      }
    }
    .detail-type {
      margin-top: 50px;
    }
    .detail-box {
      padding: 18px 38px 0px 40px;
      background-color: #ffffff;
      .detail-select {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .detail-select {
          margin-left: 15px;
        }
      }
      //入驻类型
      .detail-list-box {
        margin-top: 15px;
        .detail-list {
          display: flex;
          align-self: center;
          gap: 30px;
          margin-left: 160px;
          margin-right: 100px;
          .detail-radio {
            flex: 1;
            align-items: center;
            flex-direction: column;
            text-align: center;
          }
        }
      }
      //费用预估
      .detail-cost {
        margin-top: 25px;
        .detail-cost-content {
          margin-top: 77px;
          display: flex;
          align-items: center;
          margin-left: 137px;
          .cost-content {
            padding-left: 49px;
            padding-right: 50px;
            width: 211px;
            height: 97px;
            border-right: 1px solid #eeeeee;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .cost-title {
              width: 210px;
              height: 26px;
              font-size: 20px;
              // font-family: MicrosoftYaHei;
              color: #4a4a4a;
              line-height: 26px;
            }
            .cost-info {
              margin-top: 4px;
              width: 216px;
              height: 17px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 17px;
              //visibility: hidden;
            }
            .cost-money {
              margin-top: 10px;
              font-size: 40px;
              // font-family: MicrosoftYaHei;
              color: #ee9118;
              line-height: 52px;
            }
          }
          .cost-content:last-child {
            border-right: 0;
          }
        }
      }
      //资质要求
      .detail-certification {
        width: 1126px;
        margin-top: 25px;
        .detail-certification-content {
          margin-top: 25px;
        }
      }
    }
  }
  .table-head-cell {
    background: '#f2f2f2';
    color: '#333333';
    font-size: '12px';
    text-align: center;
  }
  //文案下加横线
  .detail-label {
    width: 260px;
    font-size: 20px;
    // font-family: MicrosoftYaHei;
    color: #4a4a4a;
    line-height: 26px;
    .underline {
      width: 50px;
      height: 6px;
      background: linear-gradient(90deg, #f6ba4f 0%, #ea8427 100%);
      border-radius: 4px;
      margin-top: 4px;
    }
  }
  // 文本控制
  .text {
    overflow: hidden;
    white-space: nowrap; /*把文本强制显示在一行*/
    text-overflow: ellipsis; /*超出部分显示...*/
  }
  //入驻按钮
  .in-btn {
    width: 280px;
    height: 59px;
    background: linear-gradient(270deg, #ff8600 0%, #ff4a2d 100%);
    box-shadow: 0px 10px 15px 0px rgba(255, 84, 37, 0.3);
    border-radius: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 20px;
    margin: 77px auto 80px auto;
  }
  .in-btn:hover {
    cursor: pointer;
  }
  .in-btn > i {
    display: block;
    width: 12px;
    height: 12px;
    box-sizing: border-box;
    border: 2px solid #ffffff;
    border-left: 0;
    border-bottom: 0;
    transform: rotate(45deg);
    margin-left: 10px;
  }
}
::v-deep {
  .el-input--suffix {
    width: 498px;
    height: 40px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #e6e6e6;
  }
}
</style>